@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply !scroll-smooth;
}

body {
  @apply flex min-h-screen flex-col antialiased bg-black text-white;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  padding-top: var(--cigar-bar-height, "0px");
}

section {
  @apply scroll-m-28;
}

a,
input,
button {
  @apply focus-visible:outline-none;
}

a,
button,
[role="button"] {
  -webkit-tap-highlight-color: transparent;
}

@layer base {
  :root {
    --shiki-color-text: theme("colors.white");
    --shiki-token-constant: theme("colors.emerald.300");
    --shiki-token-string: theme("colors.emerald.300");
    --shiki-token-comment: theme("colors.zinc.500");
    --shiki-token-keyword: theme("colors.sky.300");
    --shiki-token-parameter: theme("colors.pink.300");
    --shiki-token-function: theme("colors.violet.300");
    --shiki-token-string-expression: theme("colors.emerald.300");
    --shiki-token-punctuation: theme("colors.zinc.200");
  }

  [inert] ::-webkit-scrollbar {
    display: none;
  }
}

@layer components {
  .container-fluid {
    @apply mx-auto w-full max-w-7xl px-8 lg:px-12 xl:px-8 2xl:px-6;
  }

  .with-sidebar .container-fluid {
    @apply mx-auto lg:ml-0 w-full max-w-7xl px-8 lg:px-12 xl:px-8 2xl:px-6;
  }

  .swiper-gallery {
    @apply pointer-events-none;

    .swiper-slide {
      @apply pointer-events-none select-none md:opacity-0 md:transition-opacity md:duration-300;
    }

    .swiper-slide.swiper-slide-active,
    .swiper-slide.swiper-slide-next {
      @apply pointer-events-auto select-auto md:opacity-100;
    }
  }

  .md-list li {
    padding: 8px 0;
  }
}

@layer utilities {
  .tiny-scrollbar {
    &-dark {
      &::-webkit-scrollbar {
        @apply w-1;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-full bg-gray-100;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-full bg-gray-10;
      }
    }

    &-showcase {
      &::-webkit-scrollbar {
        @apply w-1;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-full bg-transparent;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-full bg-gray-300;
      }

      &--vertical {
        &::-webkit-scrollbar-track {
          @apply my-6;
        }
      }
    }

    &-light {
      &::-webkit-scrollbar {
        @apply w-1;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-full bg-gray-10;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-full bg-gray-100;
      }
    }
  }

  .search {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .range-track {
    @apply h-0.5 w-full rounded-sm bg-gray-400;
  }

  .range-thumb {
    @apply relative z-10 h-3 w-3 appearance-none rounded-full bg-white;
  }

  .gradient-circles {
    @apply container-fluid block w-screen h-screen absolute inset-0 z-0 opacity-20 pointer-events-none select-none;
    @apply before:w-[600px] before:h-[600px] before:absolute before:z-0;
    @apply before:left-0 before:top-0 before:-translate-x-1/2 before:rounded-full before:bg-gradient-radial-1;
    @apply after:w-[600px] after:h-[600px] after:absolute after:z-0;
    @apply after:right-0 after:top-0 after:translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:bg-gradient-radial-1;
  }

  .animated-border {
    --border-width: 2px;

    position: relative;
    isolation: isolate;
    border: var(--border-width) solid transparent;

    &:before {
      content: " ";
      position: absolute;
      inset: calc(var(--border-width) * -1);
      z-index: -1;
      border: inherit;
      border-radius: inherit;
      background-image: conic-gradient(
        from var(--angle),
        #75a5ff 80%,
        #acc5f6 88%,
        #acc5f6 92%,
        #75a5ff 100%
      );
      background-origin: border-box;
      mask: linear-gradient(black, black), linear-gradient(black, black);
      mask-clip: content-box, border-box;
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      animation: gradientSpin 4s linear infinite paused;
    }

    &.active {
      animation-play-state: running;
    }
  }

  @supports not (background: paint(something)) {
    .animated-border {
      &:befrore {
        background-image: conic-gradient(
          #75a5ff 80%,
          #acc5f6 88%,
          #acc5f6 92%,
          #75a5ff 100%
        );
      }
    }
  }

  @property --angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

  @keyframes gradientSpin {
    to {
      --angle: 1turn;
    }
  }
}

input[type="range"] {
  --val: calc((var(--range-value, 50) - 0) * 1%);
  @apply relative w-full cursor-pointer appearance-none rounded-sm border-none bg-gray-400 outline-none focus-visible:z-10 focus-visible:outline-2 focus-visible:outline-blue-400;
  @apply before:absolute before:left-0 before:top-0 before:z-0 before:h-0.5 before:w-[var(--range-value)] before:rounded-sm before:bg-white before:content-[""];
  @apply after:absolute after:-top-1.5 after:left-0 after:z-10 after:h-3.5 after:w-full;
}

input[type="range"]::-webkit-slider-runnable-track {
  @apply range-track;
}

input[type="range"]::-webkit-slider-thumb {
  @apply range-thumb -mt-1.5;
}

input[type="range"]::-moz-range-track {
  @apply range-track;
}

input[type="range"]::-moz-range-thumb {
  @apply range-thumb;
}

input[type="range"]::-ms-track {
  @apply range-track;
}

input[type="range"]::-ms-thumb {
  @apply range-thumb;
}

input[type="range"]::-ms-fill-lower {
  @apply rounded-sm bg-white;
}

input[type="range"]::-ms-fill-upper {
  @apply rounded-sm bg-gray-400;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff var(--val),
    #2d2d2d var(--val),
    #2d2d2d 100%
  );
}

input[type="range"]::-moz-range-progress {
  @apply bg-white;
}

input[type="range"]::-ms-fill-lower {
  @apply bg-white;
}
